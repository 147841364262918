import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FoodCard from "src/views/menu/components/FoodCard/FoodCard";
import { Box,Grid } from "@mui/material";
import { useSelector } from "react-redux";

function RelatedCardSlider() {
  const joinData = useSelector((state) => state.menus.joinData);
 
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      className="center__carousel"
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
     <Box sx={{ mt: "3.5rem 2rem" }} className="slick__popular">
        <Slider {...settings}>
          {joinData[0]?.map((fooditem, index) => (
            <Grid item key={`${fooditem.item_id}-${index}`}>
              <Box sx={{ position: "relative" }}>
                <FoodCard
                  fooditem={fooditem}
                  subcategory={`${fooditem.item_subcategory} ${fooditem.item_name}`} // Concatenating subcategory and item name
                  price={fooditem.item_price} // Displaying price from joinData
                  image={fooditem.item_image} // Displaying image from joinData
                />
              </Box>
            </Grid>
          ))}
        </Slider>
      </Box>
    </Box>
  );
}

export default RelatedCardSlider;