import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ButtonComponent from "src/components/ui/buttons/button_component";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearCart, addToOrderList } from "../../../redux/slices/cartSlice";
import CustomerConfirmationInfo from "./customer_confirmation_info";
import SendIcon from "@mui/icons-material/Send";

const ConfirmationAlert = ({ orderedList, totalAmount, deliveryCharge, totalPrice, deliveryDetails, paymentMethod, handleConfirmOrder}) => {
  // console.log('received charge', deliveryCharge)
  const [confirm, setConfirm] = useState(false);
  const [orderConfirmed, setOrderConfirmed] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleConfirm = () => {
    handleConfirmOrder();
    setConfirm(true);
  };

  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const handleOpenConfirm = () => {
    dispatch(addToOrderList(orderedList)); // Move orderedList to alignOrderList
    setConfirm(false);
    setOrderConfirmed(true);
    dispatch(clearCart(orderedList));
    // console.log("orderd list",orderedList)
    // console.log(orderedList)
    // console.log("cleared cart items ", clearCart(orderedList));

    setTimeout(() => {
      setOrderConfirmed(false);

      navigate("/menu");
    }, 1000);
  };


  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography className="responsive_fontsize20" fontWeight="bold">
          Order Info:
        </Typography>

        {/* Display ordered items and total amount */}
        <Box sx={{ marginBottom: "1rem" }}>
          {orderedList.map((item, index) => {

            return (
              <Box key={item.id}>
                <Grid container rowSpacing={2}>
                  <Grid item xs={6} md={6}>
                    <Typography className="responsive_fontsize16">
                      {index + 1}. {item.subcategory}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Typography
                      className="responsive_fontsize16"
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {item.quantity} * Rs {item.price} = Rs {(item.quantity * item.price).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ m: "0.6rem 0" }} />
              </Box>
            );
          })}

          <Grid
            container
            rowSpacing={1}
            sx={{ backgroundColor: "#F5F5F5", padding: "0.3rem 0.6rem" }}
          >
            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography className="responsive_fontsize16">Subtotal:</Typography>
              <Typography className="responsive_fontsize16">{totalAmount}</Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography className="responsive_fontsize16">Delivery Charge:</Typography>
              <Typography className="responsive_fontsize16">
                {deliveryCharge === 0 ? "Free" : `Rs. ${deliveryCharge}`}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography className="responsive_fontsize16">
                Total Amount included VAT and Service Charge:
              </Typography>
              <Typography className="responsive_fontsize16">Rs. {totalPrice}</Typography>
            </Grid>
          </Grid>

          <Divider sx={{ m: "0.5rem 0rem" }} />

          <Grid container sx={{ height: "auto", display: "flex" }}>
            <Grid item xs={3} md={3}>
              <Typography className="responsive_fontsize16">Coupon Code : </Typography>
            </Grid>
            <Grid item xs={9} md={9} sx={{ display: "flex", gap: 1 }}>
              <TextField
                className="responsive_fontsize16"
                label="Enter your coupon code here..."
                sx={{ width: "100%", borderRadius: "40px" }}
              />
              <IconButton
                sx={{ color: "black", "&:hover": { backgroundColor: "white" } }}
              >
                <SendIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider sx={{ m: "0.5rem 0rem" }} />
        </Box>

        <Box sx={{ backgroundColor: "#F5F5F5", padding: "0.3rem 0.6rem" }}>
          <CustomerConfirmationInfo
            deliveryDetails={deliveryDetails}
            paymentMethod={paymentMethod}
          />
        </Box>

        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <ButtonComponent>No</ButtonComponent>
          {/* <ButtonComponent onClick={handleConfirmOrder}>Confirm</ButtonComponent> */}
          <ButtonComponent onClick={handleConfirm}>
            Confirm
          </ButtonComponent>
        </Box>
      </Box>

      {/* Confirmation dialog */}
      <Dialog
        open={confirm}
        onClose={handleCloseConfirm}
        sx={{
          ".MuiDialog-container": {
            justifyContent: "center",
            alignItems: "flex-start",
            mt: "5rem",
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: "0.5rem",
              width: "100%",
              height: "auto",
              p: "1.5rem",
            }}
          >
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: "4rem" }} />
            <Typography variant="h6" fontWeight="bold">
              Are you sure you want to confirm your order?
            </Typography>
            <Typography sx={{ color: "grey" }}>
              Your order will be placed and you will not be able to make any changes.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                mt: "1rem",
              }}
            >
              <ButtonComponent onClick={handleCloseConfirm}>Cancel</ButtonComponent>
              <ButtonComponent onClick={handleOpenConfirm}>Confirm Order</ButtonComponent>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Order confirmed dialog */}
      <Dialog
        open={orderConfirmed}
        onClose={handleCloseConfirm}
        sx={{
          ".MuiDialog-container": {
            justifyContent: "center",
            alignItems: "flex-start",
            mt: "5rem",
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              gap: "0.5rem",
              width: "100%",
              height: "auto",
              p: "1.5rem",
            }}
          >
            <CheckCircleOutlineIcon color="success" sx={{ fontSize: "4rem" }} />
            <Typography variant="h6" fontWeight="bold">
              Thank you for your order!
            </Typography>
            <Typography sx={{ color: "grey" }}>
              Your order has been successfully placed.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmationAlert;