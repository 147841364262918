import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import RootLayout from './layout/root_layout';
import HomePage from './pages/home_page';
import NotFoundPage from './pages/not_found_page';
import MenuPage from './pages/menu_page';
import AboutPage from './pages/about_page';
import ContactPage from './pages/contact_page';
import OfferSection from './components/offerSection/offerSection';
import DesPage from './components/foodDescriptionPage/loadPage';
import ProfilePage from './layout/nav_bar/profile/profileLoad';
import TrackPage from './section/section_track/trackPage/trackPageLoad';
import FAQSection from './section/faqSection/faqSection';
import AboutSection from './layout/footer_bar/aboutSection/aboutLandingPage';
import ContactSection from './layout/footer_bar/contactSection/contactLandingPage';
import MakeMenu from './views/menu/components/make_menu/make_menu';
import SubscriptionPage from './pages/Subscription/subscription_page';
import SubscriptionPackages from './pages/Subscription/subscriptionPackages.page';
import AvailablePlans from './pages/Subscription/available_Plans/AvailablePlanCard';
import CheckoutSection from './components/order_confirmation/checkout_section';
import Setting from './layout/nav_bar/profile/setting/settingLoad';
import LoginForm from './layout/nav_bar/form/loginInfo/LoginForm';
import SignUpForm from './layout/nav_bar/form/sign_upform';
import SecondSection from './views/menu/components/SpecialMenu/second_dataset'; 
import VegItemsListing from 'src/views/vegItems/veg_items';
import NonVegItems from 'src/views/Non-veg/nonVeg'
import CustomerLocation from './layout/map_api/customer_location';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './global.css';
import ProfileLoad from './components/profileLoad';
import EsewaSuccess from './components/order_confirmation/payment/esewa_success';
import EsewaFailed from './components/order_confirmation/payment/esewa_failure';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/make-your-menu" element={<MakeMenu />} />
          <Route path="/description" element={<DesPage />} />
          <Route path="/offer" element={<OfferSection />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/track" element={<TrackPage />} />
          <Route path="/about" element={<AboutSection />} />
          <Route path="/contact" element={<ContactSection />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/signup" element={<SignUpForm />} />
          <Route path="/menu" element={<MenuPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/faq" element={<FAQSection />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/subscriptionPackage" element={<SubscriptionPackages />} /> 
          <Route path="/availableplans" element={<AvailablePlans />} />
          <Route path="/availableplans/:frequency" element={<AvailablePlans />} />
          <Route path="/checkout" element={<CheckoutSection />} />
          <Route path="/second-section" element={<SecondSection />} />
          <Route path="/veg" element={<VegItemsListing />} />
          <Route path="/nonveg" element={<NonVegItems />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="book-your-table" element = {<CustomerLocation/>} />
          <Route path="/profile/:customerId" element={<ProfileLoad/>} />
        {/* </Route> */}
          <Route path="esewa-success" element={<EsewaSuccess/>} />
          <Route path = "esewa-failed" element={<EsewaFailed/>}/>
         </Route>
      </Routes>
    </Router>
  );
}

export default App;