import React, { useState, useEffect, useRef ,useMemo,useCallback} from "react";
import { Box, Grid, useMediaQuery, Typography } from "@mui/material";
import FoodCard from "../FoodCard/FoodCard";
import ButtonComponent from "../../../../components/ui/buttons/button_component";
import MenuPopoverComponent from "./menu_popover_component";
import SliderComponent from "./SliderComponent"; // Import the SliderComponent
import { useSelector } from "react-redux";

const SecondSectionComponent = () => {
  const [selectedCategory, setSelectedCategory] = useState("veg");
  const [filteredItems, setFilteredItems] = useState([]);
  const buttonsContainerRef = useRef(null);

  const joinData = useSelector((state) => state.menus.joinData);
  const menuItems = useMemo(() => joinData[0] || [], [joinData]); // Assuming joinData[0] contains the menu items

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));


  const filterItems = useCallback((category) => {
    if (!menuItems.length) return;

    let filtered = [];

    switch (category) {
      case "veg":
        filtered = menuItems.filter((item) =>
          item.item_category?.toLowerCase().includes("veg")
        );
        break;
      case "non-veg":
        filtered = menuItems.filter((item) =>
          item.item_category?.toLowerCase().includes("non-veg")
        );
        break;
      case "platter":
        filtered = menuItems.filter((item) =>
          item.item_category?.toLowerCase().includes("platter")
        );
        break;
      case "akabare-sukuti":
        filtered = menuItems.filter((item) =>
          item.item_category?.toLowerCase().includes("akabare-sukuti")
        );
        break;
      case "momos":
        filtered = menuItems.filter((item) =>
          item.item_category?.toLowerCase().includes("momo")
        );
        break;
      default:
        filtered = menuItems;
        break;
    }

    setFilteredItems(filtered);
  },[menuItems]);

  useEffect(() => {
    filterItems(selectedCategory);
  }, [selectedCategory, filterItems]); // Ensure menuItems is included as a dependency

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!isXs && !isSm && (
        <Typography
          className="responsive_fontsize48"
          fontWeight="bold"
          color="green"
          marginBottom={3}
          textAlign={isXs || isSm ? "center" : "left"}
        >
          BBG SPECIAL
        </Typography>
      )}

      {isXs || isSm ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: isXs || isSm ? "5px" : "20px",
          }}
        >
          <MenuPopoverComponent
            categories={[
              "veg",
              "non-veg",
              "platter",
              "akabare-sukuti",
              "momos",
            ]}
            selectedCategory={selectedCategory}
            onChange={handleCategoryChange}
          />
        </Box>
      ) : null}

      <Box
        ref={buttonsContainerRef}
        sx={{
          display: "flex",
          flexDirection: isXs || isSm ? "column" : "row",
          gap: isXs || isSm ? "10px" : "20px",
          marginTop: isXs || isSm ? "10px" : 0,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          visibility: isXs || isSm ? "hidden" : "visible",
        }}
      >
        {["veg", "non-veg", "platter", "akabare-sukuti", "momos"].map(
          (category) => (
            <ButtonComponent
              className="responsive_fontsize16"
              key={category}
              category={category}
              selectedCategory={selectedCategory}
              onClick={() => handleCategoryChange(category)}
              sx={{
                border: "1px solid black",
                backgroundColor:
                  selectedCategory === category ? "#000000" : "#ffffff",
                color: selectedCategory === category ? "#ffffff" : "#000000",
                borderRadius: "20px",
                fontWeight: "bold",
                fontFamily: "Roboto Serif",
                padding: isXs
                  ? "0.5rem 0.8rem"
                  : isSm
                  ? "0.5rem 1rem"
                  : "0.5rem 1.5rem",
                transition: "all 0.3s ease-in-out",
                boxShadow:
                  selectedCategory === category
                    ? "0px 4px 8px rgba(0, 0, 0, 0.2)"
                    : "0px 2px 4px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  color: "#ffffff",
                  backgroundColor: "#000000",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                },
                margin: isXs || isSm ? "-10px 0 10px 0" : "0",
              }}
            >
              {category}
            </ButtonComponent>
          )
        )}
      </Box>

      {/* Use SliderComponent for displaying cards */}
      {filteredItems.length >= 4 ? (
        <SliderComponent items={filteredItems} />
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: { xs: "-13rem", sm: "0px" },
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {filteredItems.map((fooditem, index) => (
              <Grid
                item
                key={`${fooditem.id}-${index}`}
                sx={{ marginBottom: isXs || isSm ? "10px" : "20px" }}
              >
                <Box sx={{ position: "relative" }}>
                  <FoodCard
                    fooditem={fooditem}
                    subcategory={`${fooditem.item_subcategory} ${fooditem.item_name}`}
                    price={fooditem.item_price}
                    image={fooditem.item_image}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default SecondSectionComponent;
