import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Typography, TextField, Grid } from "@mui/material";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
const LIBRARIES = ["places"];

function DeliveryAddress({
  formattedAddress: initialFormattedAddress,
  specialInstructions: initialFoodInstructions,
  addressTitle: initialAddressInstructions,
  addressTitle: initialAddressTitle,
  phoneNumber: initialPhoneNumber,
  onDistanceCalculated,
  onAddressUpdate,
  userName,
  address,
  email,
  mobile,
  onDetailsUpdate
}) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_BBG_MAP_API_KEY,
    libraries: LIBRARIES,
  });
  const [fullName, setFullName] = useState(userName || "");
  const [mobileNumber, setMobileNumber] = useState(mobile || "");
  const [emailAddress, setEmailAddress] = useState(email || "");
  const [locationText, setLocationText] = useState(initialFormattedAddress || "");
  const [foodInstructions, setFoodInstructions] = useState(initialFoodInstructions || "");
  const [addressInstructions, setAddressInstructions] = useState(initialAddressInstructions || "");
  const [error, setError] = useState("");
  const [isGeolocationFetched, setIsGeolocationFetched] = useState(false);
  const autocompleteRef = useRef(null);

  // Update details whenever any relevant state changes
  useEffect(() => {
    // handleDetailsUpdate();
  }, [fullName, mobileNumber, emailAddress, locationText, foodInstructions, addressInstructions]);

  useEffect(() => {
    setFullName(userName || "");
  }, [userName]);

  useEffect(() => {
    setMobileNumber(mobile || "");
  }, [mobile]);

  useEffect(() => {
    setEmailAddress(email || "");
  }, [email]);

  // Function to handle details update
  const handleDetailsUpdate = () => {
    const updatedDetails = {
      fullName,
      mobileNumber,
      emailAddress,
      locationText,
      foodInstructions,
      addressInstructions,
    };
    onDetailsUpdate(updatedDetails);
  }

  const updateAddressFromCoords = useCallback(
    (coords) => {
      if (window.google.maps.Geocoder) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: coords }, (results, status) => {
          if (status === "OK" && results[0]) {
            setLocationText(results[0].formatted_address);
            onAddressUpdate(results[0].formatted_address);
          } else {
            setError("No address found for location.");
          }
        });
      }
    },
    [onAddressUpdate]
  );

  useEffect(() => {
    if (!isGeolocationFetched && navigator.geolocation && window.google) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newPos = { lat: latitude, lng: longitude };
          updateAddressFromCoords(newPos);
          setIsGeolocationFetched(true); // Mark that geolocation has been fetched
        },
        () => {
          setError("Unable to retrieve your location.");
        }
      );
    }
  }, [isLoaded, isGeolocationFetched, updateAddressFromCoords]);

  // Handle changes in form fields
  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
    handleDetailsUpdate();
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
    handleDetailsUpdate();
  };

  const handleEmailAddressChange = (e) => {
    setEmailAddress(e.target.value);
    handleDetailsUpdate();
  };

  const handleManualLocationChange = (event) => {
    setLocationText(event.target.value);
    setError(""); // Clear any error message if the user starts typing
  };

  const handleManualLocationUpdate = () => {
    const geocoder = new window.google.maps.Geocoder();
    if (locationText.trim() === "") return;

    geocoder.geocode({ address: locationText }, (results, status) => {
      if (status === "OK" && results[0]) {
        setLocationText(results[0].formatted_address);
        onAddressUpdate(results[0].formatted_address);
      } else {
        setError("Location not found.");
      }
    });
  };

  const onPlaceSelected = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place && place.geometry) {
        const newPos = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        setLocationText(place.formatted_address);
        onDistanceCalculated(newPos);
        onAddressUpdate(place.formatted_address);
      } else {
        setError("Place not selected properly.");
      }
    }
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <Box sx={{ mb: "1rem" }}>
        <Typography sx={{ mb: "1rem" }}>Address</Typography>
        {isLoaded && (
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
            onPlaceChanged={() => {
              const place = autocompleteRef.current.getPlace();
              if (place.geometry) {
                setLocationText(place.formatted_address);
                handleDetailsUpdate();
                onPlaceSelected();
              } 
            }}
          >
            <TextField
              label="Address *"
              value={locationText}
              onChange={handleManualLocationChange}
              onBlur={handleManualLocationUpdate}
              fullWidth
            />
          </Autocomplete>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </Box>

      <Box sx={{ mb: "1.5rem" }}>
        <Typography sx={{ mb: "1rem" }}>Your Information</Typography>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <TextField id="full-name" label="Full Name" required fullWidth
            onChange={handleFullNameChange}
              value={fullName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="mobile-number"
              label="Mobile Number"
              required
              fullWidth
              value={mobileNumber}
              onChange={handleMobileNumberChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField id="email-address" label="Email Address" fullWidth 
              value={emailAddress}
              onChange={handleEmailAddressChange}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: "1.5rem" }}>
        <TextField
          id="special-instructions"
          label="Food Instructions"
          multiline
          rows={2}
          fullWidth
          value={foodInstructions}
          onChange={(e) => {
            setFoodInstructions(e.target.value);
            handleDetailsUpdate(); // Update details on change
          }}
        />
      </Box>
      <Box sx={{ mb: "1.5rem" }}>
        <TextField
          id="address-instructions"
          label="Address Instructions"
          multiline
          rows={4}
          fullWidth
          value={addressInstructions}
          onChange={(e) => {
            setAddressInstructions(e.target.value);
            handleDetailsUpdate(); // Update details on change
          }}
        />
      </Box>
    </Box>
  );
}

export default DeliveryAddress;
