import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Header from "./cardListingHeader";
import CategorySection from "./categorySection";
import FoodItemsGrid from "./foodItemsGrid";
import MenuContainer from "./menuContainer";
import {
  setAnchorElId,
  closePopover,
  setSelectedCategory,
} from "../../../../redux/slices/menuSlice";

function MakeMenu({ foodOrdered }) {
  const dispatch = useDispatch();
  const { selectedMenus, currentMenu, openPopover, selectedCategory, joinData } = useSelector((state) => state.menus);

  const categories = [...new Set((joinData[0] || []).map((item) => item.item_name))];

  // Filter food items based on the selected category
  useEffect(() => {
    if (selectedCategory) {
      dispatch(setSelectedCategory(selectedCategory));
    }
  }, [selectedCategory, dispatch]);

  return (
    <>
      <Header
        setAnchorEl={(el) => dispatch(setAnchorElId(el))}
        openPopover={openPopover}
        handleClosePopover={() => dispatch(closePopover())}
        selectedMenus={selectedMenus}
      />

      <CategorySection
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={(category) => dispatch(setSelectedCategory(category))}
      />

      {/* Passing concatenated subcategory and item name */}
      <FoodItemsGrid
        selectedCategory={selectedCategory}
        selectedMenus={selectedMenus}
        currentMenu={currentMenu}
      />

      <MenuContainer
        selectedMenus={selectedMenus}
        currentMenu={currentMenu}
        foodOrdered={foodOrdered}
      />
    </>
  );
}

MakeMenu.propTypes = {
  foodOrdered: PropTypes.array.isRequired,
};

MakeMenu.defaultProps = {
  foodOrdered: [],
};

export default MakeMenu;
