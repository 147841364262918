import React 
// ,{useState, useEffect} 
from 'react';
import {
    Box, 
    Grid,
    Typography,
    // Button
} from '@mui/material';
// import axios from 'axios';

function CustomerConfirmationInfo({deliveryDetails, paymentMethod,orderId}) {
  const {fullName, locationText, mobileNumber, emailAddress, deliveryTime, foodInstructions, addressInstructions} = deliveryDetails;

   // Initialize formData state
  //  const [formData, setFormData] = useState({
  //   fullName: '',
  //   locationText: '',
  //   mobileNumber: '',
  //   emailAddress: '',
  //   deliveryTime: '',
  //   foodInstructions: '',
  //   addressInstructions: ''
  // });

  // Effect to update formData when deliveryDetails prop changes
  // useEffect(() => {
  //   if (deliveryDetails) {
  //     setFormData({
  //       fullName: deliveryDetails.fullName || '',
  //       locationText: deliveryDetails.locationText || '',
  //       mobileNumber: deliveryDetails.mobileNumber || '',
  //       emailAddress: deliveryDetails.emailAddress || '',
  //       deliveryTime: deliveryDetails.deliveryTime || '',
  //       foodInstructions: deliveryDetails.foodInstructions || '',
  //       addressInstructions: deliveryDetails.addressInstructions || ''
  //     });
  //   }
  // }, [deliveryDetails]);

  // const handleSubmit = async () => {
  //   try {
  //     // If orderId exists, update the order, else create a new order
  //     if (orderId) {
  //       // Update existing order
  //       const response = await axios.patch(`/bbgapi/orders/${orderId}`, formData);
  //       console.log("Order updated successfully:", response.data);
  //     } else {
  //       // Post new order
  //       const response = await axios.post('/api/orders', formData);
  //       console.log("New order created successfully:", response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error while submitting the order:", error);
  //   }
  // };
  // const handleSubmit = async () => {
  //   try {
  //     // If orderId exists, update the order, else create a new order
  //     if (orderId) {
  //       const response = await axios.patch(`/bbgapi/orders/${orderId}`, formData);
  //       console.log("Order updated successfully:", response.data);
  //     } else {
  //       const response = await axios.post('/api/orders', formData);
  //       console.log("New order created successfully:", response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error while submitting the order:", error);
  //   }
  // };
  return (
    <div>
      <Typography className="responsive_fontsize20" fontWeight="bold">
            Your info:
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Name</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  {/* Nabina Thapa */}
                  {fullName}
                  {/* {deliveryDetails.fullName} */}
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">
                  Location
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  {/* M8PH+M8 Kathmandu, Nepa */}
                  {locationText}
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">
                  Phone{" "}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  {/* 9868823474 */}
                  {mobileNumber}
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">Email</Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  {/* nabeenasinjalee@gmail.com */}
                  {emailAddress}
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">
                  Delivery Time
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  {/* Around 2:02 */}
                  {deliveryTime}
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">
                  Food Instructions
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  {/* Turn left from the machhindra bahal next galli
                  can't eat fried onions and garlics
                  not required sauces */}
                  {foodInstructions}
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">
                  Address Instructions
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  {/* Turn left from the machhindra bahal next galli
                  can't eat fried onions and garlics
                  not required sauces */}
                  {addressInstructions}
                </Typography>
              </Grid>
            </Grid>

            <Grid container rowSpacing={1}>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize16">
                  Payment Method
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Typography className="responsive_fontsize14">
                  {/* Turn left from the machhindra bahal next galli
                  can't eat fried onions and garlics
                  not required sauces */}
                  {/* {addressInstructions} */}
                  {/* Cash On Delivery */}
                  {paymentMethod}
                </Typography>
              </Grid>
            </Grid>
          </Box>
    </div>
  )
}

export default CustomerConfirmationInfo;
