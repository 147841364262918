import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
import HeaderSection from "./HeaderSection";
import GridLayout from "./GridLayout";
import MobileSlider from "./MobileSlider";
import { setSelectedCategory } from "../../../../redux/slices/menuSlice";

function FirstSection() {
  // const location = useLocation();
  // const { selectedItem } = location.state || {};
  const dispatch = useDispatch();

  const joinData = useSelector((state) => state.menus.joinData);
  const selectedCategory = useSelector((state) => state.menus.selectedCategory);

  const [searchedItems, setSearchedItems] = useState([]);
  const [isMomoChecked, setIsMomoChecked] = useState(false); // State for checkbox

  // Filter food items based on the selected category
  useEffect(() => {
    // Update searchedItems whenever selectedCategory or joinData changes
    const filteredItems = (joinData[0] || []).filter(
      (item) => item.item_name === selectedCategory
    );
    setSearchedItems(filteredItems);
  }, [selectedCategory, joinData]);

  // Get unique item names from the data
  const itemNames = [
    ...new Set((joinData[0] || []).map((item) => item.item_name)),
  ];

  const handleMomoCheckboxChange = (event) => {
    setIsMomoChecked(event.target.checked);
  };

  return (
    <Box sx={{ padding: 1, mb: 10 }}>
      {/* Header section to select categories */}
      <HeaderSection
        selectedCategory={selectedCategory}
        setSelectedCategory={(category) => dispatch(setSelectedCategory(category))}
        categories={itemNames}
      />

      {/* Conditional checkbox for Momo */}
      {selectedCategory === "Momo" && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isMomoChecked}
              onChange={handleMomoCheckboxChange}
              color="primary"
            />
          }
          label="Select Momo"
        />
      )}

      {/* Mobile slider for small screens */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSlider searchedItems={searchedItems} />
      </Box>

      {/* Grid layout for larger screens */}
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <GridLayout searchedItems={searchedItems} />
      </Box>
    </Box>
  );
}

export default FirstSection;
