import React from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";
import FoodCard from "../FoodCard/FoodCard";
import { useDispatch, useSelector } from "react-redux";
import {
  setAnchorElId,
  openPopover as openPopoverAction,
  closePopover,
  openMenuNameDialog as MenuDialog,
  closeMenuNameDialog,
} from "src/redux/slices/menuSlice";

const FoodItemsGrid = ({ selectedMenus, handleMenuUpdate, handleAddToMenu }) => {
  const dispatch = useDispatch();

  const { menuName, confirmDeleteDialog, anchorElId, selectedCategory, joinData } = useSelector((state) => state.menus);

  const filteredItems = joinData
    ? joinData[0].filter((item) => item.item_name === selectedCategory)
    : [];

  const handleOpenPopover = (event, fooditem) => {
    if (event && event.currentTarget && fooditem) {
      console.log("Dispatching food item:", fooditem);
      const newAnchorElId = event.currentTarget.id;
      dispatch(setAnchorElId(newAnchorElId));

      // Concatenating subcategory and item name
      const itemToAdd = {
        ...fooditem,
        subcategory: `${fooditem.item_subcategory} - ${fooditem.item_name}`,
      };

      dispatch(
        openPopoverAction({
          anchorElId: newAnchorElId,
          popoverID: "foodcard",
          foodItem: {
            ...itemToAdd,
            quantity: 1,
            subcategory: itemToAdd.subcategory,
          },
        })
      );
    } else {
      console.error("Event or event.currentTarget or fooditem is undefined");
    }
  };

  const handleClosePopover = () => {
    dispatch(closePopover(anchorElId));
  };

  const handleOpenMenuNameDialog = () => {
    dispatch(closePopover(anchorElId));
    dispatch(MenuDialog());
  };

  const handleCloseMenuNameDialog = () => {
    dispatch(closeMenuNameDialog());
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {filteredItems.length > 0 ? (
        filteredItems.map((fooditem, index) => (
          <Grid item key={`${fooditem.item_id}-${index}`} xs={12} sm={6} md={4} lg={3}>
            <Box
              sx={{
                position: "relative",
                ml: { xs: 0, md: 10 },
                width: { xs: "100%", sm: "80%", md: "100%", lg: "95%" },
              }}
            >
              <FoodCard
                fooditem={fooditem}
                subcategory={`${fooditem.item_subcategory}  ${fooditem.item_name}`}
                selectedMenus={selectedMenus}
                isSelected={fooditem.quantity > 0}
                quantity={fooditem.quantity}
                handleMenuUpdate={handleMenuUpdate}
                handleAddToMenu={handleAddToMenu}
                handleOpenPopover={handleOpenPopover}
                handleClosePopover={handleClosePopover}
                handleOpenMenuNameDialog={handleOpenMenuNameDialog}
                handleCloseMenuNameDialog={handleCloseMenuNameDialog}
                menuName={menuName}
                confirmDeleteDialog={confirmDeleteDialog}
              />
            </Box>
          </Grid>
        ))
      ) : (
        <Box>No items available in this category.</Box>
      )}
    </Grid>
  );
};

FoodItemsGrid.propTypes = {
  selectedMenus: PropTypes.arrayOf(
    PropTypes.shape({
      menuName: PropTypes.string.isRequired,
      selectedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    })
  ).isRequired,
  handleMenuUpdate: PropTypes.func.isRequired,
  handleAddToMenu: PropTypes.func.isRequired,
};

export default FoodItemsGrid;
