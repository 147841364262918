import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import SearchBar from "../search_bar/search_bar_main";
import SocialMediaBar from "../social_media_bar/social_media_bar_main";
import FoodCard from "./desFoodCard";
import IncrementDecrement from "../../views/menu/components/makeMenuItems/IncrementDecrement";
import IngredientsAndFeedback from "./ingredientsAndFeedback";
import CustomButton from "./customButton";
import {
  addItem,
  addToCheckout,
  moveCheckoutToCart,
} from "src/redux/slices/cartSlice";
import { setSelectedFoodItem } from "./store/actions";
import { getSelectedFoodItem } from "./store/selectors";

function Description() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const foodItem = useSelector(getSelectedFoodItem);
  console.log("foodItem are", foodItem);

  const getCurrentPrice = () => {
    const hour = new Date().getHours();
    const isDay = hour >= 6 && hour < 18;

    let prices = [];
    try {
      if (foodItem.item_price) {
        prices = JSON.parse(foodItem.item_price);
      }
    } catch (e) {
      console.error("Error parsing item_price:", e);
      return 0; // Return 0 if there's an error
    }

    // Return the appropriate price
    if (prices.length > 1) {
      return isDay ? prices[0] : prices[1];
    } else if (prices.length === 1) {
      return prices[0];
    } else {
      return 0; // Return 0 if no prices are available
    }
  };

  const price = getCurrentPrice();

  const [quantity, setQuantity] = useState(1);
  const [itemIngredients, setItemIngredients] = useState([]);

  useEffect(() => {
    if (location.state?.foodItem) {
      dispatch(setSelectedFoodItem(location.state.foodItem));
    }
  }, [location.state, dispatch]);

  useEffect(() => {
    if (foodItem) {
      try {
        const ingredients = JSON.parse(foodItem.item_ingredients);
        setItemIngredients(ingredients);
      } catch (error) {
        console.error("Error parsing item_ingredients:", error);
      }
    }
  }, [foodItem]);
  const handleAddToCart = () => {
    const itemToAdd = {
      ...foodItem,
      quantity,
      subcategory: location.state?.subcategory || "",
    };
    dispatch(addItem(itemToAdd));
    toast.success(`Added ${itemToAdd.subcategory} to your cart`);
  };

  const handleAddToCheckout = () => {
    const itemToAdd = {
      ...foodItem,
      quantity,
      subcategory: location.state?.subcategory || "",
    };
    dispatch(addToCheckout(itemToAdd));
    navigate("/checkout");
    toast.success(`Added ${itemToAdd.subcategory} to checkout`);
  };

  useEffect(() => {
    if (location.pathname !== "/checkout") {
      dispatch(moveCheckoutToCart());
    }
  }, [location.pathname, dispatch]);

  if (!foodItem || !foodItem.image) {
    return <Box>Loading... or No image available</Box>;
  }
  const stars = Array.from({ length: 3 });
  return (
    <Box>
      <SearchBar />
      <SocialMediaBar />
      <Box sx={{ backgroundColor: "#f5f5f5", py: 3, px: { xs: 2, md: 4 } }}>
        <Grid container spacing={{ xs: 3, md: 6 }}>
          <Grid item xs={12} sm={6} md={6}>
            <FoodCard foodItem={foodItem} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography
              className="responsive_fontsize48"
              sx={{ color: "#166F2A", fontWeight: 500 }}
            >
              {location.state?.subcategory}
            </Typography>

            <Typography className="responsive_fontsize18" variant="body1">
              {foodItem.item_description}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <IncrementDecrement
                count={quantity}
                onIncrement={() => setQuantity(quantity + 1)}
                onDecrement={() => setQuantity(Math.max(1, quantity - 1))}
              />
              <Typography sx={{ mb: 1 }}>Price: Rs {price}</Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <CustomButton text="Add to Cart" onClick={handleAddToCart} />
                <CustomButton text="Order Now" onClick={handleAddToCheckout} />
              </Box>
            </Box>
          </Grid>
        </Grid>

        
        <IngredientsAndFeedback
          stars={stars}
          itemIngredients={itemIngredients}
        />
      </Box>
    </Box>
  );
}

export default Description;
