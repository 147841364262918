import React, { useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  closePopover,
  setMenuName,
  closeMenuNameDialog,
  openConfirmDeleteDialog,
  closeConfirmDeleteDialog,
  addItemToMenu,
  addMenu,
} from "src/redux/slices/menuSlice";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoritePopover from "src/views/menu/components/make_menu/Popover_make_menu";
import FoodCardContent from "./FoodCardContent";
import FoodCardActions from "./FoodCardAction";
import MenuDialogs from "./MenuCardDialog";
import { addItem} from "src/redux/slices/cartSlice";

const bufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

function FoodCard({
  fooditem,
  subcategory,
  quantity,
  handleDeleteCard,
  handleIncrement,
  handleDecrement,
  handleOpenPopover,
  handleClosePopover,
  handleOpenMenuNameDialog,
  handleCloseMenuNameDialog,
  menuName,
  confirmDeleteDialog,
}) {
  // const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const anchorElRef = useRef(null);

  const {
    openPopover,
    popoverID,
    foodItemToAdd,
    selectedFoodItem,
    selectedMenus,
    openMenuNameDialog,
    anchorElId,
  } = useSelector((state) => state.menus);
  
  const getCurrentPrice = () => {
    const hour = new Date().getHours();
    const isDay = hour >= 6 && hour < 18; 

    let prices = [];
    try {
      if (fooditem.item_price) {
        prices = JSON.parse(fooditem.item_price);
      }
    } catch (e) {
      console.error("Error parsing item_price:", e);
      return 0; // Return 0 if there's an error
    }

    // Return the appropriate price
    if (prices.length > 1) {
      return isDay ? prices[0] : prices[1];
    } else if (prices.length === 1) {
      return prices[0];
    } else {
      return 0; // Return 0 if no prices are available
    }
  };

  const price = getCurrentPrice();

  console.log("price ", price);
  
  const base64Image = fooditem.item_image
    ? `data:image/png;base64,${bufferToBase64(fooditem.item_image.data)}`
    : "/path/to/default/image.jpg";

  const handleAddToCart = () => {
    const concatenatedSubcategory = `${fooditem.item_subcategory} ${fooditem.item_name}`.trim();
    
    // Make sure the price is a valid number
    const itemPrice = parseFloat(price);
    if (isNaN(itemPrice)) {
      toast.error("Invalid price. Please try again.");
      return; // Exit if the price is invalid
    }
    
    const itemToAdd = {
      id: fooditem.item_id,
      subcategory: concatenatedSubcategory,
      price: itemPrice, 
      quantity: 1,
      image: base64Image,
      detail: fooditem.item_description
    };
    
    dispatch(addItem(itemToAdd));
    toast.success(`${concatenatedSubcategory} added to cart!`);
  };

  // const handleCheckout = () => {
  //   dispatch(addToCheckout()); 
  //   toast.success("Items moved to checkout!");
  // };

  const isMenuNameExists = (name) => {
    return selectedMenus.some((menu) => menu.menuName === name);
  };

  const handleSaveMenuName = () => {
    if (menuName.trim() === "") {
      toast.error("Menu name cannot be empty, please add a new name.");
    } else if (!isMenuNameExists(menuName)) {
      dispatch(addMenu({ menuName }));
      dispatch(closeMenuNameDialog());
      dispatch(addItemToMenu({ menuName, item: foodItemToAdd }));
      dispatch(closePopover());
      toast.success("Menu created and item added.");
    } else {
      dispatch(addItemToMenu({ menuName, item: foodItemToAdd }));
      dispatch(closeMenuNameDialog());
      toast.warn("Menu name already exists. Item added to existing menu.");
    }
  };

  const handleInputChange = (e) => {
    dispatch(setMenuName(e.target.value));
  };

  const handleClose = () => {
    dispatch(closeMenuNameDialog());
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Card
        sx={{
          maxWidth: 300,
          width: { xs: "85%", sm: "90%", md: "90%", lg: "95%" },
          height: "auto",
          position: "relative",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          margin: { xs: "1rem auto", sm: "1rem auto", md: "2rem auto" },
          textAlign: "center",
          display: "flex",
          gap: 3,
          flexDirection: "column",
          alignItems: "center",
          border: quantity > 0 ? "2px solid green" : "none",
        }}
      >
        {quantity >= 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.3rem",
              justifyContent: "center",
            }}
          >
            <FoodCardActions
              quantity={quantity}
              handleIncrement={handleIncrement}
              handleDecrement={handleDecrement}
              handleDeleteCard={handleDeleteCard}
            />
          </Box>
        )}

        <IconButton
          sx={{
            position: "absolute",
            padding: "10px",
            top: 0,
            left: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={(event) => handleOpenPopover(event, fooditem)}
          id={`favorite-icon-${fooditem.id}`}
          ref={anchorElRef}
        >
          <FavoriteIcon
            sx={{
              color: "#FF0B0B",
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              },
            }}
          />
        </IconButton>

        <FoodCardContent
          fooditem={fooditem}
          subcategory={subcategory}
          quantity={quantity}
          handleAddToCart={handleAddToCart}
          item_image={fooditem.item_image}
        />

      </Card>

      {openPopover &&
        popoverID === "foodcard" &&
        anchorElId === `favorite-icon-${fooditem.id}` && (
          <FavoritePopover
            open={openPopover}
            onClose={handleClosePopover}
            foodItemToAdd={foodItemToAdd}
            foodItem={selectedFoodItem}
            onAddMenuName={() => handleOpenMenuNameDialog(fooditem, anchorElId)}
            menuName={selectedMenus.map((menu) => menu.menuName)}
            anchorEl={document.getElementById(anchorElId)}
            handleMenuSelect={() => {}}
          />
        )}

      {openMenuNameDialog && (
        <MenuDialogs
          openMenuNameDialog={openMenuNameDialog}
          setOpenMenuNameDialog={handleCloseMenuNameDialog}
          menuName={menuName}
          setMenuName={(value) => dispatch(setMenuName(value))}
          confirmDeleteDialog={confirmDeleteDialog}
          setConfirmDeleteDialog={(state) =>
            state
              ? dispatch(openConfirmDeleteDialog())
              : dispatch(closeConfirmDeleteDialog())
          }
          deleteMenuName={menuName}
          confirmDelete={() => {}}
          handleInputChange={handleInputChange}
          handleClose={handleClose}
          handleSaveMenuName={handleSaveMenuName}
          fooditem={fooditem}
        />
      )}
    </Box>
  );
}

FoodCard.propTypes = {
  fooditem: PropTypes.shape({
    item_id: PropTypes.string.isRequired,
    item_subcategory: PropTypes.string.isRequired,
    item_name: PropTypes.string.isRequired,
    item_image: PropTypes.string.isRequired,
    item_price: PropTypes.string.isRequired,
  }).isRequired,
  subcategory: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  handleDeleteCard: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  handleDecrement: PropTypes.func.isRequired,
  handleOpenPopover: PropTypes.func.isRequired,
  handleClosePopover: PropTypes.func.isRequired,
  handleOpenMenuNameDialog: PropTypes.func.isRequired,
  handleCloseMenuNameDialog: PropTypes.func.isRequired,
  menuName: PropTypes.string.isRequired,
  confirmDeleteDialog: PropTypes.bool.isRequired,
};

export default FoodCard;
