import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";

function CardIngredients({
  food,
  sliderIndex,
  ingredientAnimateClass,
  setIngredientAnimateClass,
}) {
  const animationDelay = 200;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIngredientAnimateClass("ingredient-enter");
    }, 50);

    return () => clearTimeout(timer);
  }, [sliderIndex, setIngredientAnimateClass]);

  // Handle item_ingredients
  let ingredients = [];
  try {
    // Check if item_ingredients is a string that needs parsing
    if (typeof food.item_ingredients === 'string') {
      ingredients = JSON.parse(food.item_ingredients);
    } else if (Array.isArray(food.item_ingredients)) {
      ingredients = food.item_ingredients;
    }
  } catch (e) {
    console.error("Error parsing item_ingredients:", e);
  }

  console.log("ingredients", ingredients); // Debugging line

  return (
    <Box
      sx={{
        width: "30%",
        position: "relative",
        display: { md: "block", xs: "none" },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          filter: "blur(113px)",
        }}
      ></Box>
      <Box sx={{ mt: "10%" }}>
        <Typography
          className="responsive_fontsize24"
          sx={{
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Ingredients
        </Typography>
        <Box
          className="responsive_fontsize18"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          {ingredients.length > 0 ? (
            ingredients.map((ingredient, index) => {
              const delay = ingredientAnimateClass ? index * animationDelay : 0;
              return (
                <Typography
                  key={index}
                  sx={{
                    fontSize: "1.25rem",
                    fontWeight: "400",
                    textAlign: "center",
                    opacity: ingredientAnimateClass ? 0 : 1,
                    animation: ingredientAnimateClass
                      ? `slideInFromRight 0.5s ease-out ${delay}ms forwards`
                      : "none",
                  }}
                >
                  {ingredient}
                </Typography>
              );
            })
          ) : (
            <Typography
              sx={{
                fontSize: "1.25rem",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              No ingredients available
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default CardIngredients;
